import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {getTranslation, hasModule} from '../../Helpers/Default'
import CalendarWidget from './Calendar'
import {observer} from 'mobx-react'
import MessagesWidget from './Messages'
import MainContacts from './MainContacts'
import DashboardStore from '../../Stores/Dashboard'
import SvjStore from '../../Stores/Svj'
import UserStore from '../../Stores/User'
import {toJS} from 'mobx'
import ActivityTable from './Activity'
import {EVENTS_MODULE, MESSAGES_MODULE} from '../../Constants/Modules'
import {userHasModule} from '../../Helpers/User'
//
// const DashboardPage: FC = () => (
//   <>
//     {/* begin::Row */}
//     <div className='row gy-5 g-xl-8'>
//       <div className='col-xxl-4'>
//         <MixedWidget2
//           className='card-xl-stretch mb-xl-8'
//           chartColor='danger'
//           chartHeight='200px'
//           strokeColor='#cb1e46'
//         />
//       </div>
//       <div className='col-xxl-4'>
//         <ListsWidget5 className='card-xxl-stretch' />
//       </div>
//       <div className='col-xxl-4'>
//         <MixedWidget10
//           className='card-xxl-stretch-50 mb-5 mb-xl-8'
//           chartColor='primary'
//           chartHeight='150px'
//         />
//         <MixedWidget11
//           className='card-xxl-stretch-50 mb-5 mb-xl-8'
//           chartColor='primary'
//           chartHeight='175px'
//         />
//       </div>
//     </div>
//     {/* end::Row */}
//
//     {/* begin::Row */}
//     <div className='row gy-5 gx-xl-8'>
//       <div className='col-xxl-4'>
//         <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
//       </div>
//       <div className='col-xl-8'>
//         <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
//       </div>
//     </div>
//     {/* end::Row */}
//
//     {/* begin::Row */}
//     <div className='row gy-5 g-xl-8'>
//       <div className='col-xl-4'>
//         <ListsWidget2 className='card-xl-stretch mb-xl-8' />
//       </div>
//       <div className='col-xl-4'>
//         <ListsWidget6 className='card-xl-stretch mb-xl-8' />
//       </div>
//       <div className='col-xl-4'>
//         <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
//         {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
//       </div>
//     </div>
//     {/* end::Row */}
//
//     <div className='row g-5 gx-xxl-8'>
//       <div className='col-xxl-4'>
//         <MixedWidget8
//           className='card-xxl-stretch mb-xl-3'
//           chartColor='success'
//           chartHeight='150px'
//         />
//       </div>
//       <div className='col-xxl-8'>
//         <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
//       </div>
//     </div>
//   </>
// )

const DashboardWrapper: FC = observer(() => {
  const intl = useIntl()
  const [committee, setCommittee] = React.useState<any>([])

  useEffect(() => {
    async function getData() {
      DashboardStore.getData()
      UserStore.getAll()
      setCommittee(toJS(SvjStore.committees))
    }

    getData()
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{getTranslation('MENU.DASHBOARD', intl)}</PageTitle>
      {DashboardStore.pinnedMessages.length > 0 && userHasModule(MESSAGES_MODULE) ? (
        <div className='row mb-8'>
          <div className='col-12'>
            <MessagesWidget
              icon={'/media/icons/duotune/communication/com009.svg'}
              title={'DASHBOARD.MESSAGES.PINNED_MESSAGES'}
              link={'/zpravy?tab=pined'}
              messages={DashboardStore.pinnedMessages}
            />
          </div>
        </div>
      ) : null}
      {userHasModule(MESSAGES_MODULE) ? (
        <div className='row mb-8'>
          <div className='col-12'>
            <MessagesWidget messages={DashboardStore.messages} />
          </div>
        </div>
      ) : null}
      <div className='row'>
        {userHasModule(EVENTS_MODULE) ? (
          <div className='col-12 col-md-6 col-xl-6'>
            <CalendarWidget />
          </div>
        ) : null}

        <div className='col-12 col-md-6 col-xl-6'>
          <MainContacts users={UserStore.users} />
        </div>
      </div>
      {/*<div className='row mt-8'>*/}
      {/*  <div className='col-12'>*/}
      {/*    <ActivityTable />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  )
})

export {DashboardWrapper}
