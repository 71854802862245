import {DefaultBadge} from '../../Components/Badge'
import Translation from '../../Components/Translation'
import {CONTACT_CATEGORY_MAP, CONTACT_TYPE_MAP} from '../../Constants/Contact'
import {
  getContactIcon,
  getFullName,
  getRoleName,
  getShortcutInCircle,
  getUserStatusMark,
  hasCrudRole,
  renderFullNameWithIcon,
} from '../User'
import {getTranslation} from '../Default'
import {USERS_MODULE} from '../../Constants/Modules'
import * as React from 'react'

export const getCategoryLabel = (category: string) => {
  // @ts-ignore
  const text = CONTACT_CATEGORY_MAP[category].label
  let colorClass = 'white'

  switch (category) {
    case CONTACT_CATEGORY_MAP.administrative.value:
      colorClass = 'color_1_dark'
      break
    case CONTACT_CATEGORY_MAP.banks_financial_services.value:
      colorClass = 'color_2_dark'
      break
    case CONTACT_CATEGORY_MAP.energy.value:
      colorClass = 'color_3_dark'
      break
    case CONTACT_CATEGORY_MAP.it.value:
      colorClass = 'color_4_dark'
      break
    case CONTACT_CATEGORY_MAP.lawyer.value:
      colorClass = 'color_5_dark'
      break
    case CONTACT_CATEGORY_MAP.svj.value:
      colorClass = 'color_6_dark'
      break
    case CONTACT_CATEGORY_MAP.real_estate_management.value:
      colorClass = 'color_7_dark'
      break
    case CONTACT_CATEGORY_MAP.construction_work.value:
      colorClass = 'color_8_dark'
      break
    case CONTACT_CATEGORY_MAP.accounting_services.value:
      colorClass = 'color_9_dark'
      break
    case CONTACT_CATEGORY_MAP.property_maintenance.value:
      colorClass = 'color_10_dark'
      break
    case CONTACT_CATEGORY_MAP.plant_care.value:
      colorClass = 'color_11_dark'
      break
    case CONTACT_CATEGORY_MAP.authorities.value:
      colorClass = 'color_12_dark'
      break
    case CONTACT_CATEGORY_MAP.other.value:
      colorClass = 'color_13_dark'
      break
  }

  return (
    <DefaultBadge color={colorClass}>
      <Translation text={text} />
    </DefaultBadge>
  )
}

export const getTypesLabel = (type: string) => {
  // @ts-ignore
  const text = CONTACT_TYPE_MAP[type].label
  let colorClass = 'white'
  if (CONTACT_TYPE_MAP.person.value === type) {
    colorClass = 'light-primary'
  } else if (CONTACT_TYPE_MAP.company.value === type) {
    colorClass = 'light-success'
  }
  return (
    <DefaultBadge color={colorClass}>
      <Translation text={text} />
    </DefaultBadge>
  )
}

export const getContactName = (contact: any) => {
  let user = {
    titleBefore: '',
    firstName: '',
    lastName: '',
    titleAfter: '',
    isCompany: true,
  }
  if (contact.type === CONTACT_TYPE_MAP.person.value) {
    user = {
      titleBefore: contact.title_before,
      firstName: contact.first_name,
      lastName: contact.last_name,
      titleAfter: contact.title_after,
      isCompany: false,
    }
  } else if (contact.type === CONTACT_TYPE_MAP.company.value) {
    const split_company_name = contact.company_name.split(' ')

    if (split_company_name.length > 1) {
      user.firstName = split_company_name[0].toUpperCase()
      user.lastName = split_company_name[1].toUpperCase()
    } else {
      user.firstName = split_company_name[0].toUpperCase()
    }
  }

  return (
    <div className='d-flex align-items-center'>
      {getContactIcon({...contact, ...user})}
      <div className='d-flex justify-content-start flex-column m-0 ms-3'>
        <div className='text-dark fs-6 align-content-center d-inline-flex'>
          {user.isCompany ? <strong>{contact.company_name}</strong> : getFullName(user)}
        </div>
        {contact.position ? (
          <div>
            <span className={'fs-8 text-muted'}>{contact.position}</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export const getContactTypeList = (intl: any) => {
  const options: any = []

  for (let i in CONTACT_TYPE_MAP) {
    // @ts-ignore
    const option = CONTACT_TYPE_MAP[i]
    options.push({value: option['value'], label: getTranslation(option['label'], intl)})
  }

  return options
}

export const getContactCategoryList = (intl: any) => {
  const options: any = []

  for (let i in CONTACT_CATEGORY_MAP) {
    // @ts-ignore
    const option = CONTACT_CATEGORY_MAP[i]
    options.push({value: option['value'], label: getTranslation(option['label'], intl)})
  }

  return options
}
